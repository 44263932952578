import React from "react";
import ArrowButton from "@/common/arrow-button";
import {
  ArrowButtonWrapper,
  CollapseParagraph,
  PediatricDentalCollapse,
  ProcedureContainer,
  PediatricDentalParagraph,
  PediatricDentalSpan,
  PediatricDentalTitle,
  DentistContainer,
  DentistImg,
  ProcedureMain,
} from "./dental-procedure";
import { DentistImgData } from "constants/pediatric-dental-treatments-img";
import Image from "@/common/image";

interface Props {
  aboutServices?: any;
  circles?: boolean;
  invitationDescription?: string;
}

const DentalProcedure = ({
  aboutServices,
  circles,
  invitationDescription,
}: Props) => {
  return (
    <ProcedureMain>
      {circles && (
        <DentistContainer>
          {DentistImgData.map((item, i) => (
            <DentistImg key={i}>
              <Image filename={item.image} alt={item.image} />
            </DentistImg>
          ))}
        </DentistContainer>
      )}
      <ProcedureContainer>
        {aboutServices?.map((item: any, i: any) => {
          const { title, starterText, content, list } = item;
          return (
            <div key={i} data-aos="fade-up">
              {title && <PediatricDentalTitle>{title}</PediatricDentalTitle>}
              {starterText && (
                <PediatricDentalParagraph>
                  {starterText}
                </PediatricDentalParagraph>
              )}
              {list && (
                <ul>
                  {list.map((item: any, i: any) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              )}
              {content?.map((cont: any, ind: any) => (
                <PediatricDentalParagraph key={ind}>
                  {cont.boldText && (
                    <PediatricDentalSpan>{cont.boldText}</PediatricDentalSpan>
                  )}
                  {cont.normalText}
                  {cont?.list?.length > 1 && (
                    <ul>
                      {cont.list.map((item: any, i: any) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ul>
                  )}
                </PediatricDentalParagraph>
              ))}
            </div>
          );
        })}
        <PediatricDentalCollapse>
          <CollapseParagraph>{invitationDescription}</CollapseParagraph>
          <a href="tel:+16153018660">
            <ArrowButtonWrapper>
              <ArrowButton
                title="Call Us"
                direction="row-reverse"
                rotate="rotate(45deg)"
              />
            </ArrowButtonWrapper>
          </a>
        </PediatricDentalCollapse>
      </ProcedureContainer>
    </ProcedureMain>
  );
};

export default DentalProcedure;
